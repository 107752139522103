'<template>
  <div class="row justify-content-md-center" :class="{'loading': !loaded}">
    <!-- INICIO Detalhes -->
    <div class="col-md-3 text-left dark-box">

      <div class="row" >
        <div class="row col-12 mb-5">
          <div  class="row col-12 mb-3">
            <div class="col-12 text-muted">Número Oportunidade:</div>
            <div class="col-12 ">{{ modalData.numeroOportunidade }} </div>
          </div>
          <div  class="row col-12 mb-3">
            <div class="col-12 text-muted">Cliente:</div>
            <div class="col-12 ">{{ modalData.cliente }} </div>
          </div>
          <div class="row col-12 mb-3">
            <div class="col-12 text-muted">Solicitante</div>
            <div class="col-12 ">{{ modalData.solicitante }}</div>
          </div>
        </div>

        <div class="row col-12 mb-5">
          <div class="row col-12 mb-3">
            <div class="col-12 text-muted">Produto</div>
            <div class="col-12 ">{{ modalData.produto }}</div>
          </div>
          <div class="row col-12 mb-3">
            <div class="col-12 text-muted">Origem</div>
            <div class="col-12 ">{{ modalData.origem }}</div>
          </div>
          <div class="row col-12 mb-3">
            <div class="col-12 text-muted">Destino</div>
            <div class="col-12 ">{{ modalData.destino }}</div>
          </div>
          <div class="row col-12 mb-3">
            <div class="col-12 text-muted">Mercadoria</div>
            <div class="col-12 ">{{ modalData.mercadoria }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- FIM Detalhes -->

    <div class="col-9 modal-box">
      <div class="close-button mb-4">
        <div class="btn btn-link buttonClose d-inline-block" @click="onClose()">
          <span><i class="text-primary rc-Ativo-36"> </i></span>
        </div>
      </div>
      <div class="row">
        <!-- Situação -->
        <div class="col-12 col-md-12">
          <div class="md-form mb-2">
            <label class="active">
              Situação
            </label>
            <select class="md-form-control" v-model="situacao" id="recompra" name="recompra"
              v-validate="{ required: true }"
            >
              <option value="1">Aguardando</option>
              <option value="2">Trabalhando</option>
              <option value="3">Opção encontrada</option>
              <option value="4">Nenhuma opção encontrada</option>
            </select>
          </div>
          <transition name="slide" mode="">
            <small v-if="errors.first('recompra')" class="royalc-error-field">{{ errors.first('recompra') }}</small>
          </transition>
        </div>
        <!-- Observação -->
        <div class="col-12 col-md-12">
          <custom-textarea
            v-model="observacao"
            name="observacao"
            label="Observação"
            type="text"
            rootClassName="md-form mb-0"
            inputClassName="md-form-control"
            :rows="10"
            v-validate="{ required: false }"
            background="#ffffff"
            :error="errors.first('observacao')">
          </custom-textarea>
        </div>
        <!-- Arquivo -->
        <div class="col-md-12 form-group mt-2">
          <label class="active">
            Arquivo de E-mail
          </label>
          <custom-file-upload
            v-model="files"
            name="attach"
            v-validate="{ required: false }"
            :max-files="1"
            :error="errors.first('attach')">
          </custom-file-upload>
        </div>

      </div>

      <div class="row mt-3">
        <div class="col-12 text-center">

          <button  type="button" class="btn btn-primary btn-fill btn-rounded mb-4 mr-3" @click="onSave()">{{ $t('actions.update_status') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import OportunidadeService from '@/services/OportunidadeService'
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'
import CustomFileUpload from '@/components/Forms/CustomFileUpload.vue'

import VeeValidate from 'vee-validate'
import { locale, maxDimensionsRule } from '@/utils/validator'

import 'cropperjs/dist/cropper.css'

VeeValidate.Validator.localize({ 'pt_BR': locale })
// Inclusão de cláusula de validação de tamanho máximo para imagem
VeeValidate.Validator.extend('maxdimensions', maxDimensionsRule)
Vue.use(VeeValidate, { locale: 'pt_BR', fieldsBagName: 'formFields' })

export default {
  name: 'OportunidadeAddInfo',
  props: {
    modalData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      loaded: true,
      observacao: null,
      situacao: null,
      files: []
    }
  },
  components: {
    CustomTextarea,
    CustomFileUpload
  },
  methods: {

    onClose () {
      this.$emit('close')
    },
    onSave (scopeName) {
      let me = this
      this.$validator.validateAll(scopeName).then((result) => {
        if (result) {
          me.loaded = false

          let historic = {
            observacao: this.observacao,
            situacao: this.situacao,
            files: this.files
          }

          OportunidadeService.changeStatus(this.modalData.id, historic)
            .then(function (res) {
              if (res) {
                me.$emit('close')
                global.instanceApp.$alertSwal.toast('Dados alterados', 'success')
              }
            })
            .catch(() => {
              me.loaded = true
            })
        }
      })
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    }
  }
}
</script>

<style scoped>
  .loading {
    pointer-events: none;
    opacity: 0.4;
  }

  .justify-content-md-center {
    padding: 20px;
  }

  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .img-container:hover .img-profile {
    opacity: 0.3;
  }

  .img-container:hover .middle {
    opacity: 1;
  }

  .modal-box{
    padding: 75px;
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 33px;
  }
</style>
