import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)
const urlAPI = process.env.VUE_APP_APIENDPOINT

export const OportunidadeService = {
  post (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/oportunidade`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  get (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/oportunidade/${id}`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            reject(err)
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  put (id, formData) {
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/oportunidade/${id}`, formData).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  delete (formData) {
    // Todo validate form data
    try {
      return new Promise(function (resolve, reject) {
        Http.delete(`${urlAPI}v2/oportunidade/${formData.id}`).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data, httpCode: res.status })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  changeStatus (id, formData) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/oportunidade/${id}/historico`, formData).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getHistorico (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/oportunidade/${id}/historico`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            reject(err)
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getFile (id, fileName) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/oportunidade/${id}/file/${fileName}`, {
          responseType: 'blob'
        }).then(function (res) {
          resolve({ data: res.data })
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default OportunidadeService
